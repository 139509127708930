import React from "react"

import "./index.scss"

import InfoIcon from "../components/info-icon"
import ShopCard from "../components/shop-card"

import Email from "../shared_components/emailobfuscator"
import Seo from "../shared_components/seo"

import "@fortawesome/fontawesome-free/css/all.css"

const IndexPage = () => {
  return (
    <div>
      <Seo title="San Mateo Honey" description="Locally sourced raw honey in San Mateo, CA. Available to pickup or by delivery." />

      <div className="info-container">
        <InfoIcon icon="fas fa-truck" title="SHIPS TO ALL STATES">
          1 - 3 days delivery
        </InfoIcon>
        <InfoIcon icon="far fa-calendar-check" title="FREE LOCAL PICKUP">
          SF Bay Area only
        </InfoIcon>
        <InfoIcon icon="fas fa-spa" title="ECO-FRIENDLY">
          100% raw & organic
        </InfoIcon>
        <InfoIcon icon="fas fa-hand-holding-heart" title="SMALL BATCH">
          Hand harvested & packaged
        </InfoIcon>
      </div>

      <div id="shop" style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
        <ShopCard>
          <div style={{textAlign: "center"}}>
            <div style={{fontWeight: "bold"}}>Price (by net weight)</div>
            <div style={{margin: "16px"}}>
              <div>10oz jar - $20</div>
              <div>24oz jar - $40</div>
            </div>
          </div>
          <div style={{fontWeight: "bold", textAlign: "center"}}>
            San Mateo Local Raw Honey
          </div>
          <div style={{textAlign: "center"}}>
            <p>
              Pure raw honey harvested in San Mateo, CA. Color is light to medium amber.
              Flavor may include hints of orange and assorted local flowers.
            </p>
            <p>
              The local flowers the bees were interested in:
              lavender, mint, lantana, wild flowers, blueberries, strawberries, California lilac, honeysuckle.
            </p>
          </div>
        </ShopCard>
      </div>

      <div style={{background: "#eee", margin: "10px -4vw", padding: "5px 4vw"}}>
      <h1 id="contact">Contact</h1>
        To place an order or send an inquiry, contact us on Instagram or send us an email. Please specify the product you
        want and provide the time you want to pick up.

        <p>
          Email: <Email name="thehoneyverse" domain="gmail" tld="com" style={{textDecoration: "underline"}} />
        </p>
        <p>
          <i style={{color: "#FD3B6A"}} className="fab fa-instagram" aria-hidden="true" /> Instagram: <a href="https://www.instagram.com/thehoneyverse">@thehoneyverse</a>
        </p>
      </div>

      <h1 id="about">About</h1>
      <div className="small-header">
        <div>Where are the bees?</div>
      </div>

      <div>
        <div>We produce 100% raw & organic honey on our small urban farm in San Mateo, CA.</div>
      </div>

      <div className="small-header">
        <div>How much do you produce?</div>
      </div>
      <div>
        <div>We only have capacity to take good care of 2 colonies at the moment.</div>
      </div>

      <div className="small-header">
        <div>Tell me a little about the bees!</div>
      </div>
      <div>
        <div>
          Our bees are hygienic, gental and locally raised. They are of Italian descent, which is thought to originate from
          the continental part of Italy, south of the Alps, and north of Sicily. The bees harvest nectar seasonally from the
          flowers and plants in our neighborhood in San Mateo, CA. Unlike commericial apiaries, we do not chemically
          treat our bees.
        </div>
       </div>

      <div className="small-header">
        <div>What's the benefits of local raw honey?</div>
      </div>
      <div>
        <div>
          Local raw honey kill certain bacteria with its antimicrobial properties, soothe a sore throat and reduces the lifespan of colds.
          Because the honey is havested from local plants, having a spoon of honey every day can help with seasonal allergies.
        </div>
      </div>

      <div className="small-header">
        <div>How do you extract the honey?</div>
      </div>
      <div>
        <div>
          We hand extract the honey from the frames, using a high speed spinning extractor. Then we run the honey
          through a 200 micron filter to remove big chucks of honeycomb and honey wax. Then we package the honey!
          That’s it. 100% raw and nothing is added to it.
        </div>
      </div>

      <div className="small-header">
        <div>Why aren’t you using plastic bottles?</div>
      </div>
      <div>
        The best way to store honey is in a glass jar. All pure honey will crystalize. To liquify crystalized honey, the best
        way is to warm up the honey in the container in water of 150F/65C. Plastic might melt at this temperature, so
        glass jars are way better! Real honey does not crystalize as fast as the sugar added honey that's beed sold in grocery
        stores, and has longer shelf life.
      </div>

      <div className="small-header">
        <div>What size should I buy?</div>
      </div>
      <div>
        If you want to add honey to your drink, recommend 1-2 tsp for 1 cup of liquid. Roughly there are 20 tsp in the 6oz jar, 
        33 tsp in the 10oz jar, 80 tsp in the 24oz jar. 
      </div>

      <div className="small-header">
        <div>Can pregnant women or breastfeeding women eat raw honey?</div>
      </div>
      <div>
        Yes, it is safe for pregnant and breastfeeding women to eat raw honey.
      </div>

      <div className="small-header">
        <div>Can baby eat raw honey?</div>
      </div>
      <div>
        Do not feed any kind of honey to baby under 1 year old, and it's not recommended for children under 2 years old.
      </div>
    </div>
  )
}

export default IndexPage
